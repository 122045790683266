import _merge from "lodash.merge";
import * as types from "@/store/mutation-types";
import {
  isBirthDateValid,
  isBirthZipcodeValid,
  isBirthCityValid,
  isBirthCountryValid,
  isBirthLastNameValid,
  isCivilityValid,
  isNationalityValid,
} from "@/store/helpers";
import { MERCHANT } from "@/store/namespaces";

// Initial state
const state = {
  all: {},
  account: undefined,
};

// Getters
const getters = {
  allAccounts: (accountState) => accountState.all,
  email: (accountState, accountGetters, rootState, rootGetters) => {
    return (rootGetters.rawEmail || accountState.account?.email)?.trim();
  },
  firstName: (accountState) => {
    return accountState.account?.first_name;
  },
  lastName: (accountState) => {
    return accountState.account?.last_name;
  },
  phoneNumber: (accountState, accountGetters, rootState, rootGetters) => {
    return (rootGetters.rawPhoneNumber || accountState.account?.phone_number)?.trim();
  },
  birthDate: (accountState) => {
    return accountState.account?.birth_date;
  },
  birthCountry: (accountState) => {
    return accountState.account?.birth_country;
  },
  birthCity: (accountState) => {
    return accountState.account?.birth_city;
  },
  birthZipcode: (accountState) => {
    return accountState.account?.birth_zipcode;
  },
  birthLastName: (accountState) => {
    return accountState.account?.birth_last_name;
  },
  civility: (accountState) => {
    return accountState.account?.civility;
  },
  nationality: (accountState) => {
    return accountState.account?.nationality;
  },
  isBirthDateValid: (accountState, currentGetters, rootState, rootGetters) => {
    const merchant = rootGetters[`${MERCHANT}/merchant`];
    return isBirthDateValid(merchant, currentGetters.birthDate);
  },
  isBirthZipcodeValid: (accountState, currentGetters, rootState, rootGetters) => {
    const merchant = rootGetters[`${MERCHANT}/merchant`];
    return isBirthZipcodeValid(
      merchant,
      currentGetters.birthCountry,
      currentGetters.birthZipcode
    );
  },
  isBirthLastNameValid: (accountState, accountGetters, rootState, rootGetters) => {
    const merchant = rootGetters[`${MERCHANT}/merchant`];
    return isBirthLastNameValid(merchant, accountGetters.birthLastName);
  },
  isCivilityValid: (accountState, accountGetters, rootState, rootGetters) => {
    const merchant = rootGetters[`${MERCHANT}/merchant`];
    return isCivilityValid(merchant, accountGetters.civility);
  },
  isBirthCityValid: (accountState, currentGetters, rootState, rootGetters) => {
    const merchant = rootGetters[`${MERCHANT}/merchant`];
    return isBirthCityValid(merchant, currentGetters.birthCity);
  },
  isBirthCountryValid: (accountState, currentGetters, rootState, rootGetters) => {
    const merchant = rootGetters[`${MERCHANT}/merchant`];
    return isBirthCountryValid(merchant, currentGetters.birthCountry);
  },
  isBirthplaceValid: (accountState, currentGetters) => {
    return (
      currentGetters.isBirthZipcodeValid &&
      currentGetters.isBirthCityValid &&
      currentGetters.isBirthCountryValid
    );
  },
  isNationalityValid: (accountState, currentGetters, rootState, rootGetters) => {
    const merchant = rootGetters[`${MERCHANT}/merchant`];
    return isNationalityValid(merchant, currentGetters.nationality);
  },
};

const actions = {
  setBirthCountry({ commit }, { birthCountry }) {
    commit(types.APP_SET_BIRTH_COUNTRY, { birthCountry: birthCountry?.trim() });
  },
};

// Mutations
const mutations = {
  [types.SHARE_GET_SUCCESS](state, { entities }) {
    state.all = _merge({}, state.all, entities.accounts);
  },
  [types.SET_ACCOUNT](state, { account }) {
    state.account = account;
  },
  [types.APP_SET_BIRTH_COUNTRY](state, { birthCountry }) {
    state.account = { ...state.account, birth_country: birthCountry };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
