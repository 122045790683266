<script>
import { mapGetters } from "vuex";
import { BI_PROVIDER_LABELS } from "@/constants";
import funnelProcessingMixin from "@/mixins/funnelProcessingMixin";
import { useFunnelStep } from "@/composables/funnelStep";
import { useError } from "@/composables/error";
import { usePlugin } from "@/composables/plugin";
import { useOperator } from "@/composables/operator";
import { MERCHANT, ORDER } from "@/store/namespaces";
import browserRedirectToUrl from "@/helpers/browserRedirectToUrl";

export default {
  mixins: [funnelProcessingMixin],
  setup(_, ctx) {
    const { handleError, handleInegibilityError } = useError();
    const { funnelStepDone } = useFunnelStep(ctx);
    const { postMessageParent } = usePlugin();
    const { operatorTeamName } = useOperator();
    return {
      handleError,
      handleInegibilityError,
      funnelStepDone,
      postMessageParent,
      operatorTeamName,
    };
  },
  data() {
    return {
      isAnalysisNeeded: false,
      bankInsightUrl: undefined,
      BIProvider: undefined,
    };
  },
  computed: {
    ...mapGetters(ORDER, ["order", "orderUid"]),
    ...mapGetters(MERCHANT, ["faqUrl", "merchantName"]),
    ...mapGetters(["rawIsAnalysisInProgress"]),
    providerLabel() {
      return BI_PROVIDER_LABELS[this.BIProvider];
    },
  },

  mounted() {
    if (!this.share && !this.order)
      throw new Error("Share or Order is needed to call status api");
    this.checkStatus();
  },

  unmounted() {
    if (this.statusTimeoutID) {
      clearTimeout(this.statusTimeoutID);
    }
  },

  methods: {
    redirectToBankin() {
      browserRedirectToUrl(this.bankInsightUrl);
    },
    async checkStatus() {
      try {
        const isSeminal = this.rawIsSeminal ? 1 : 0;
        const { data, statusCode } = await this.service.getStatus(this.uid, isSeminal);
        if (statusCode !== 200) {
          /* Wait for another /status http call to decide. */
          this.retryCheckStatus();
          return;
        }
        if (data.bank_insight_url) {
          this.isAnalysisNeeded = true;
          this.bankInsightUrl = data.bank_insight_url;
          this.BIProvider = data.bi_provider;
          return;
        }
        if (data.summary_pending) {
          this.isLoading = false;
          this.funnelStepDone();
          return;
        }
        this.endFunnel(data);
      } catch (apiResponseError) {
        this.handleStatusFailure(apiResponseError);
      }
    },
  },
};
</script>

<template lang="pug">
ScreenTitle(:title="$t('title_processing', { operatorTeamName })")

.screen.confirmation-screen.flex.flex-col.justify-around.items-center
  .screen-section.flex(style='padding-top: 30px')
    h1(v-if='errorHuman' v-t="'payment_cancelled'")
    h1(v-else-if='isAnalysisNeeded' v-t="'payment_analysis_needed'")
    h1(v-else-if='rawIsAnalysisInProgress' v-t="'payment_analysis_in_progress'")
    h1(v-else v-t="'payment_pending'")

  .screen-section.section-error.text-center(v-if='errorHuman')
    p(v-html='errorHuman')

  .screen-section.loader-section.flex(v-if='!isAnalysisNeeded')
    Loader(v-if='isLoading')

  .screen-section.flex.flex-col(v-if='!errorHuman')
    div(v-if='isAnalysisNeeded')
      .info-title.flex.flex-col
        span.px-4.pt-4.text-sm.leading-4.text-dark.text-justify(v-t="'payment_analysis_information_1'")
        span.px-4.pb-4.text-sm.leading-4.text-dark.text-justify(v-t="{ path: 'payment_analysis_information_2', args: { providerLabel } }")

      p.text-sm.p-4.leading-4.text-dark.text-justify(v-t="{ path: 'payment_analysis_information_3', args: { operatorTeamName } }")

    div(v-else)
      p.text-center.p-4.text-sm.leading-4.text-dark(v-t='informationMessage' v-for='informationMessage in informationMessages' :key='informationMessage')

  .screen-section.flex-c-c.flex-col(v-if='isAnalysisNeeded')
    .grid
      ElButton.my-4.w-full(
        @click='redirectToBankin'
        type="primary"
      )
        .grow.text-sm(v-t="'submit'")

      a.my-4.no-underline(:href='faqUrl' target='_blank')
        ElButton.w-full(type="info")
          .grow.text-sm(v-t="'see_faq'")

    p.text-sm.p-4.leading-4.text-dark.text-justify(v-t="{ path: 'payment_analysis_information_4', args: {merchantName} }")

    .logo-section.flex
      img.logo.flex(v-for="logo in ['rgpd', 'acpr', 'dsp2']" :src='`/static/images/${logo}.png`' :key='logo')

  SofincoLogo
</template>
