import { mapGetters } from "vuex";
import { PLUGIN_EVENTS, FRONT_URL_PARAMS, TransactionTypes } from "@/constants";
import ERROR_TYPES from "@/constants/errors";
import config from "@/config";
import browserRedirectToUrl from "@/helpers/browserRedirectToUrl";
import { sendMatomoPaymentSuccessfulDimension } from "@/plugins/matomo";
import { MERCHANT, SHARE } from "@/store/namespaces";
import Loader from "@/components/BaseLoader.vue";
import SofincoLogo from "@/components/SofincoLogo.vue";
import ScreenTitle from "@/components/ScreenTitle.vue";
import ShareService from "@/api/shareService";
import OrderService from "@/api/orderService";
import AppContext from "@/AppContext";

export default {
  components: {
    Loader,
    SofincoLogo,
    ScreenTitle,
  },
  emits: ["checkoutRequired"],
  data() {
    return {
      isLoading: true,
      waitingStatusTime: 0,
      errorHuman: undefined,
      statusTimeoutID: undefined,
    };
  },
  computed: {
    ...mapGetters(SHARE, ["share", "shareUid", "isPrimary"]),
    ...mapGetters(MERCHANT, ["enableCustomSuccessScreen"]),
    ...mapGetters(["rawEmbedded", "rawRedirectUrl", "rawIsSeminal"]),
    informationMessages() {
      if (this.rawIsAnalysisInProgress) {
        return this.buildMessages("payment_analysis_progress_information", 2);
      }
      return this.buildMessages("payment_pending_information", 2);
    },
    hasRedirectUrl() {
      return !!this.rawRedirectUrl;
    },
    module() {
      const transaction_service = {
        [TransactionTypes.PURCHASE]: {
          service: ShareService,
          targetUid: this.shareUid,
        },
        [TransactionTypes.ORDER]: { service: OrderService, targetUid: this.orderUid },
      };
      return transaction_service[AppContext.transactionType];
    },
    service() {
      return this.module.service;
    },
    uid() {
      return this.module.targetUid;
    },
  },
  methods: {
    buildMessages(prefix, numberOfMessages) {
      const messages = [];
      for (let idx = 0; idx < numberOfMessages; idx += 1) {
        messages.push(`${prefix}_${idx + 1}`);
      }
      return messages;
    },
    buildEndOfFunnelSuccessUrl(data) {
      const url = new URL(this.rawRedirectUrl);
      url.searchParams.append(FRONT_URL_PARAMS.PLEDG_SUCCESS, JSON.stringify(data));
      return url.href;
    },
    endFunnelSuccess(data) {
      if (this.hasRedirectUrl) {
        const endOfFunnelUrl = this.buildEndOfFunnelSuccessUrl(data);
        browserRedirectToUrl(endOfFunnelUrl);
      } else if (this.rawEmbedded) {
        this.postMessageParent(PLUGIN_EVENTS.PLEDG_SUCCESS, data);
      } else {
        throw new Error("Unreachable");
      }
    },
    retryCheckStatus() {
      if (this.waitingStatusTime > config.CONFIRMATION_TIMEOUT) {
        /* Processing is taking too long, abort processing. */
        this.isLoading = false;
        this.errorHuman = this.$t("Confirmation3DSTimeoutError");

        /* Save error and redirect cancel / close iframe with error. */
        this.handleError(
          "processing_timeout",
          ERROR_TYPES._3DS_CONFIRMATION_TIMEOUT,
          this.errorHuman,
          { reportError: true }
        );
        return;
      }
      this.waitingStatusTime += config.CHECK_STATUS_INTERVAL;
      this.statusTimeoutID = setTimeout(this.checkStatus, config.CHECK_STATUS_INTERVAL);
    },
    handleStatusFailure(apiResponseError) {
      if (apiResponseError.isInfraError) {
        /* Network error or similar. */
        this.retryCheckStatus();
        return;
      }

      this.isLoading = false;

      const { statusCode, errorHuman, backError } = apiResponseError;
      this.errorHuman = errorHuman;

      if (statusCode !== 400) {
        this.handleError(
          "processing_get_status_failure",
          ERROR_TYPES.PAYMENT_REFUSED,
          this.errorHuman,
          {
            reportError: false,
          }
        );
        return;
      }

      if (backError?.app_error === ERROR_TYPES.REFUSED_PAYMENT) {
        this.handleError(
          "processing_refused_payment",
          ERROR_TYPES.PAYMENT_REFUSED,
          this.errorHuman,
          {
            reportError: false,
          }
        );
        return;
      }
      if (backError?.app_error === ERROR_TYPES.TRANSACTION_TIMEOUT) {
        this.handleError(
          "transaction_timeout",
          ERROR_TYPES.TRANSACTION_TIMEOUT,
          this.errorHuman,
          {
            reportError: false,
          }
        );
        return;
      }
      if (backError?.app_error === ERROR_TYPES.NOT_ELIGIBLE) {
        this.errorHuman = this.$t("errorHappened");
        this.handleInegibilityError("processing_not_eligible", backError?.app_error, {
          reportError: false,
        });
        return;
      }
      if (
        backError?.app_error === "refused_card" ||
        backError?.app_error === "3d_not_supported"
      ) {
        /* Let the customer try another card. */
        this.$emit("checkoutRequired", {
          error: this.errorHuman,
        });
        return;
      }
      // Report unexpected error
      this.handleError(
        "processing_unexpected_error",
        ERROR_TYPES.PAYMENT_REFUSED,
        "Unknown bad parameters error"
      );
    },
    endFunnel(data) {
      if (this.rawIsSeminal) {
        if (!this.isPrimary) throw new Error("Unreachable");
        if (this.enableCustomSuccessScreen && this.rawEmbedded) {
          /* This a very specific customer workflow, we display the success screen and
           * ends the Funnel workflow by triggering a success event.
           */
          this.isLoading = false;
          this.funnelStepDone();
        }
        /* handle payment completion. */
        sendMatomoPaymentSuccessfulDimension(true);
        this.endFunnelSuccess(data);
        return;
      }
      sendMatomoPaymentSuccessfulDimension(true);
      this.isLoading = false;
      this.funnelStepDone();
    },
  },
};
