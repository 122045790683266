export default {
  /** ----------------------------------------------------
   *                        ANCV
   *  ---------------------------------------------------- */
  title_pay_with_ancv: "Mit Ihrem Chèque-Vacances Connect-Konto bezahlen",
  try_again:
    "Die Zahlung über Chèque-Vacances ist fehlgeschlagen. Bitte versuchen Sie es erneut.",
  important: "Wichtig: ",
  ancv_app_mandatory:
    "Die Chèque-Vacances-Anwendung ist erforderlich, um diese Zahlung zu validieren",
  ancv_id: "Ihre Chèque-Vacances Connect ID",
  open_ancv_app:
    "Öffnen Sie Ihre ANCV-Chèque-Vacances-Anwendung, um den Zahlungsvorgang abzuschließen",
  transaction_success: "Ihre Transaktion wurde erfolgreich registriert.",
  transaction_infos_below: "Nachfolgend finden Sie die Informationen zu Ihrer Zahlung.",
  see_you: "Bis bald",
  ancv_signature: "Das Team der Nationalen Agentur für Chèque-Vacances.",
  ancv_amount: "Erhaltener Betrag",
  total_amount: "Beantragter Betrag",
  ancv_transaction_id: "Referenz der Transaktion",
  remaining_amount: "Restschuld",
  excluding_fees: "ohne Finanzierungskosten",
  installment_cta: "Den Restbetrag in {installments_nb}x per Kreditkarte bezahlen",
  deferred_cta: "Später den fälligen Restbetrag per Kreditkarte bezahlen",
  infos_ancv_and_bnpl:
    "Sobald Sie den in Chèque-Vacances zu zahlenden Betrag mithilfe der Chèque-Vacances-Anwendung bestätigt haben, wird Ihnen ein Ratenzahlungsplan auf der Grundlage des noch ausstehenden Betrags per Kreditkarte angeboten.",
  warning_delay_bnpl:
    "Sie haben {delayInMinutes} Minuten Zeit, um Ihre Zahlung abzuschließen. Nach Ablauf dieser Frist werden die Zahlung in Chèque-Vacances und Ihre Buchung storniert.",
  /** ----------------------------------------------------
   *                        CHECKOUT
   *  ---------------------------------------------------- */
  input_bank_details: "Ich gebe meine Bankdaten ein",
  amount_to_pay: "Zu zahlender Betrag",
  total_purchase_amount: "Gesamtbetrag",
  split_explanation: "Wenn alle Teilnehmer innerhalb von 48 Stunden zahlen, zahle ich ",
  i_pay_my_share: "Ich bezahle meinen Anteil",
  i_pay_my_installment: "Ich bezahle meine monatliche Zahlung",
  anticipated_payment_warning:
    "Sie sind dabei, die Fälligkeit von <b>{date}</b>, d. h. <b>{days} tage</b> vor dem ursprünglich vereinbarten Datum, begleichen",
  i_change_my_payment_method: "Aktualisieren Sie meine Zahlungsmethode",
  payment_method_fingerprint_amount:
    "Möglicherweise wird auf Ihrem Konto ein Abdruck Ihrer Bankkarte über einen Betrag von {amount} angezeigt. Dies ist vorübergehend und es wird kein Betrag von Ihnen abgezogen.",
  card_number: "Kartennummer",
  expiration_date: "Ablaufdatum",
  security_code: "Sicherheitscode",
  card_network: "Kartennetzwerk",
  notice_payment: "Hinweise zur Nutzung des Zahlungsdienstes",
  accept_cta_installment:
    "Indem ich auf Bestätigen klicke, stimme ich zu {0}. Schließlich bestätige ich, dass die zur Bezahlung meines Kaufs verwendete Karte einem auf meinen Namen eröffneten Konto zugeordnet ist.",
  split_default_message:
    "Ich werde einen Antrag auf Genehmigung des Gesamtbetrags erhalten. Es wird kein Betrag abgebucht. Meine Freunde haben 48 Stunden Zeit, um zu bezahlen. Wenn einer von ihnen nach dieser Zeit nicht zahlt, wird sein Anteil von mir abgebucht.",
  secured_payment: "Sichere Bezahlung",
  deadline_on: "Rate fällig am",
  with_fees_amount: "(einschließlich {amount} Gebühren)",
  basket_amount: "Höhe des Warenkorbs:",
  ill_pay_later: "Ich werde am {date} bezahlen",
  ill_pay_later_on_delivery: "Ich bezahle per Nachnahme (und spätestens am {date})",
  penalties_detail:
    "einschließlich {penalties_percentage}% verspäteter Strafen: {amount}",
  not_supported_cards:
    "Prepaid-Karten mit systematischer Autorisierung wie Electron, Maestro, Nickel Account, Revolut und virtuelle Karten oder E-Cards werden nicht akzeptiert.",
  payment_method_issue:
    "Bei der Verwendung Ihrer Zahlungsmethode ist ein Fehler aufgetreten. Bitte kontaktieren Sie das Support-Team des Händlers.",
  order_info_multiple_shipment:
    "Eine Bestellung kann aus mehreren Sendungen bestehen. Jede Sendung hat ihren eigenen Zeitplan, der mit dem Versand beginnt.",
  deposit_deadline_on: "Anzahlung",
  balance_deadline_on: "Bilanzstichtag am",
  balance_to_pay_later: "Ich werde den Restbetrag am {date} bezahlen",
  test_another_card: "Versuchen Sie es mit einer anderen Karte.",
  entry_error: "Eingabefehler.",
  card_declined: "Ihre Karte wurde abgelehnt.",
  invalid_iban: "Ihr IBAN ist falsch",
  stripe_library_not_loaded: "Stripe V3-Bibliothek wurde nicht geladen",
  taeg: "Sie schließen eine Zahlungsvereinbarung für {num_of_days} Tage ab, für die {amount} in Rechnung gestellt werden, was einem effektiven Jahreszins von {taeg}% entspricht.",
  amount_paid_in_ancv: "In Chèque-Vacances bezahlter Betrag",
  advertisement_optin:
    "Ich möchte kommerzielle Kommunikation von {optin_advertiser} erhalten",
  electronic_optin:
    "Ich möchte elektronische kommerzielle Kommunikation von CA Consumer Finance erhalten",
  phone_mail_optout:
    "Ich möchte keine telefonische oder postalische kommerzielle Kommunikation von CA Consumer Finance erhalten",
  contract_message_start: "Ich akzeptiere die ",
  contract_message_link_installment:
    "vorvertraglichen und vertraglichen Informationen zur Ratenzahlung ",
  contract_message_link_deferred:
    "vorvertraglichen und vertraglichen Informationen zur Ratenzahlung ",
  contract_message_end:
    "und bestätige, dass die Karte, mit der ich meinen Einkauf bezahlt habe, mit einem auf meinen Namen eröffneten Konto verbunden ist.",
  /** ----------------------------------------------------
   *                        COMMONS
   *  ---------------------------------------------------- */
  add: "Hinzufügen",
  me: "Mir",
  friend: "Freund",
  submit: "Bestätigen",
  yes: "Ja",
  no: "Nein",
  update: "Bearbeiten",
  apply: "Anwenden",
  see_faq: "Lesen Sie unsere FAQs, um mehr zu erfahren",
  language: "Sprache:",
  back_to_website: "Zurück zur {website}",
  unknown: "Unbekannt",
  on_date: "Am {date}",
  footer_question_to_merchant:
    "Bei Fragen zu Ihrem Kauf wenden Sie sich bitte direkt an {merchantName}.",
  footer_question_to_pledg:
    'Für weitere Informationen zu Ihrer Finanzierung konsultieren Sie unsere <a href="{faqUrl}" target="_blank">FAQ</a> oder schreiben Sie uns an <a href="mailto:{contactEmail}"> {contactEmail} </a>.',
  notice_access_link: "Hinweis zur Nutzung des Zahlungsdienstes",
  data_policy_link:
    "<a href='{dataPolicyUrl}' target='_blank'>Datenschutzerklärung</a>",
  data_policy:
    "Meine Daten werden im Rahmen einer vollautomatischen Verarbeitung verwendet, die die Gewährung einer Zahlungserleichterung ermöglicht: <a href='{dataPolicyUrl}' target='_blank'>datenschutzerklärung</a>.",
  contact_access_link:
    "<a href='{contactUrl}' target='_blank'>Zahlungsdienst kontaktieren</a>",
  no_information: "Keine Angaben gemacht",
  no_psp_source: "Keine Zahlungsmethode verfügbar",
  redirected_from_message:
    "{merchantName} hat Sie zu {operatorTeamName} weitergeleitet, um Ihre Zahlung abzuschließen.",
  your_purchase: "Ihr Einkauf",
  your_financing: "Ihre Finanzierung",
  your_payment: "Ihre Zahlung",
  purchase_amount: "Betrag des Kaufs",
  monthly_installments: "{installments_nb} Monatsraten von",
  fixed_borrowing_rate: "Fester Sollzinssatz",
  nationality_short: "Nationalität",
  birth_last_name_short: "Name bei der Geburt",
  email_address: "E-Mail-Adresse",
  full_address: "Postanschrift",
  birthdate: "Geburtsdatum",
  birthplace: "Geburtsort",
  firstname: "Vorname",
  lastname: "Nachname",
  phone_number_short: "Telefonnummer",
  read_more: "Mehr erfahren",
  continue: "Weiter",
  /** ----------------------------------------------------
   *                    DASHBOARD BUYER
   *  ---------------------------------------------------- */
  notice_infos: "Bitte beachten Sie die Hinweise zum Zahlungsdienst",
  error_invalid_contact: "Kontakt ist ungültig",
  fees_amount: "(Gebühren: {amount} {currency})",
  here: "hier",
  deferred_intro:
    "Status Ihres aufgeschobenen Kaufs von {date} bis {hour} ({timezone_city} time) am {merchantName}.",
  installment_intro:
    "Status Ihres geteilten Kaufs von {date} bis {hour} ({timezone_city} time) am {merchantName}.",
  split_intro:
    "Status Ihres gemeinsamen Kaufs vom {date} bis {hour} (Zeit {timezone_city}) am {merchantName}.",
  penalties_amount: "(Strafen: {amount} {currency})",
  proceed_payment_late: "Meine Situation regulieren: {amount} {currency}",
  proceed_payment_in_advance: "Zahlen Sie meine nächste Rate: {amount} {currency}",
  dashboard_buyer_your_basket: "Ihr Warenkorb",
  dashboard_buyer_your_fees: "Finanzierungsgebühr ({feesPercentage}%)",
  dashboard_buyer_payment_schedule: "Ihre Fälligkeitstermine",
  dashboard_buyer_products: "Ihre Produkte",
  dashboard_buyer_see_schedule: "Siehe Zeitplan",
  dashboard_buyer_overdue: "Ausbleibende Zahlung",
  dashboard_buyer_overdue_payment: "Regularisierung Ihrer Situation",
  dashboard_buyer_overdue_payment_text:
    "Die Zahlung für das Fälligkeitsdatum {date} ist fehlgeschlagen. Bitte regeln Sie Ihre Situation.",
  dashboard_buyer_your_payment_method: "Ihre Zahlungsmethode",
  dashboard_buyer_your_payment_method_expired_soon:
    "Ihre Zahlungsmethode läuft bald ab. Aktualisieren Sie es, um Zahlungsvorfälle zu vermeiden.",
  dashboard_buyer_your_payment_method_text_installment:
    "Die Abbuchung Ihrer monatlichen Raten erfolgt zu den vereinbarten Terminen mit folgender Zahlungsart:",
  dashboard_buyer_your_payment_method_text_deferred:
    "Der fällige Betrag wird zum vereinbarten Termin mit folgender Zahlungsart abgebucht:",
  dashboard_buyer_your_payment_method_button: "Meine Zahlungsmethode ändern",
  dashboard_buyer_share_captured_amount: "Bezahlt:",
  dashboard_buyer_share_not_charged_amount: "Abgesagt:",
  dashboard_buyer_share_refunded_amount: "Erstattet:",
  dashboard_buyer_share_outstanding_amount: "Noch zu zahlender Betrag:",
  dashboard_buyer_title_installment:
    'Ihre <span class="text-secondary">Zahlung in {installmentsNb} Malen</span> bei {merchantName}',
  dashboard_buyer_title_deferred:
    'Ihre <span class="text-secondary">aufgeschobene Zahlung</span> bei {merchantName}',
  dashboard_buyer_title_down_payment:
    'Ihre <span class="text-secondary">Zahlung mit Anzahlung</span> bei {merchantName}',
  dashboard_buyer_title_split:
    'Ihre <span class="text-secondary">gemeinsame Zahlung</span> bei {merchantName}',
  dashboard_buyer_status_total_refund:
    "Ihr Kauf wurde gemäß den Geschäftsbedingungen von {merchantName} und dem Zahlungsdienst zurückerstattet.",
  dashboard_buyer_status_split_waiting_payment:
    "Nicht alle Teilnehmer haben ihren Anteil bezahlt.",
  dashboard_buyer_status_fully_paid:
    "Ihr Einkauf wurde vollständig bezahlt. Bis bald bei {merchantName}.",
  dashboard_buyer_status_overdue:
    "Die Zahlung einer Rate ist fehlgeschlagen. Regulieren Sie Ihre Situation oder wenden Sie sich so schnell wie möglich an den Zahlungsdienst.",
  dashboard_buyer_status_ongoing:
    "Die Bezahlung Ihres Kaufs erfolgt ganz normal, es sind keine Maßnahmen Ihrerseits erforderlich.",
  dashboard_buyer_status_psp_source_expired:
    "Ihre Zahlungsmethode läuft ab. Aktualisieren Sie es, um Ihre Raten weiterhin bezahlen zu können.",
  resend_message: "Nachricht erneut senden",
  split_leader_label: "Sie",
  state_leader_not_paid: "Ausstehend",
  state_expired_not_paid: "Zahlung fehlgeschlagen, zu regularisieren",
  state_future_not_paid: "Automatische Abbuchung bei Fälligkeit",
  state_preauthorization_ok: "Bankimpressum",
  state_sepa_debit_pending: "SEPA-Lastschrift ausstehende Validierung",
  state_sofort_pending: "Sofortzahlung ausstehend",
  state_ancv_pending_transfer: "Warten auf Validierung",
  state_ancv_processing: "Wartet auf die Bezahlung mit Chèques-Vacances",
  state_ancv_preauthorization_ok: "Mit Chèques-Vacances bezahlt",
  state_payment_ok: "Bezahlt",
  state_paid_by_leader: "Von Ihnen bezahlt",
  state_unknown: "Warten auf Zahlung",
  state_fully_refunded: "Erstattet",
  state_fully_not_charged: "Abgesagt",
  split_explanation_1:
    "Jeder Teilnehmer hat bis zum {date} um {hour} ({timezone_city} Zeit) Zeit, um seinen Split zu bezahlen. Nach dieser Zeit wird Ihnen der Betrag Ihres Splits ({amount} {currency}) in Rechnung gestellt sowie unbezahlte Anteile.",
  dashboard_order_link: "Zurück zum Bestell-Dashboard",
  header_amount: "Betrag",
  header_participants: "Teilnehmer",
  header_status: "Status",
  error_dashboard_title: "Dashboard nicht verfügbar",
  error_dashboard_subtitle: "Ihre Kaufübersicht konnte nicht angezeigt werden.",
  error_dashboard_reason_title: "Aus welchem ​​Grund?",
  error_dashboard_reason_1: "Der betreffende Kauf ist möglicherweise nicht verfügbar",
  error_dashboard_reason_1_details:
    "Dieses Dashboard ist nicht verfügbar für Käufe, die erstellt oder storniert werden.",
  error_dashboard_reason_2: "Diese Seite ist möglicherweise abgelaufen",
  error_dashboard_reason_2_details:
    "Aus Sicherheitsgründen unterliegt diese Seite einem Ablaufdatum. Bitte schließen Sie die Seite und klicken Sie erneut auf den ursprünglichen Link.",
  /** ----------------------------------------------------
   *                        ERRORS
   *  ---------------------------------------------------- */
  purchaseNotFound: "Keine Käufe, die mit dieser URL verknüpft sind.",
  genericError:
    "Ein Fehler ist aufgetreten. Wenn der Fehler erneut auftritt, kontaktieren Sie uns bitte unter contact{'@'}pledg.co.",
  error_infra_unavailable:
    "Der Dienst ist derzeit nicht verfügbar. Bitte laden Sie die Seite neu oder versuchen Sie es später noch einmal.",
  notAddedValidDestinationWarning:
    "Bitte klicken Sie auf die Schaltfläche '+ Hinzufügen', um Ihren Freund hinzuzufügen.",
  invalidDestination: "Diese E-Mail / Handynummer ist ungültig!",
  alreadyUsedDestination: "Diese E-Mail / Handynummer wird bereits verwendet!",
  invalidAmount: "Dieser Betrag ist ungültig!",
  closingWarning: "Möchten Sie dieses Fenster wirklich schließen?",
  singleShareWarning: "Sind Sie sicher, dass Sie alleine bezahlen möchten?",
  Confirmation3DSTimeoutError:
    "Authentifizierung fehlgeschlagen. Bitte versuche es erneut.",
  ancvConfirmationTimeoutError:
    "Fehler bei der Zahlung mit Chèques-Vacances. Bitte versuchen Sie es erneut.",
  requiredPropertyMissingOrInvalid:
    "Die erforderliche Angabe {property} fehlt oder ist ungültig",
  requiredPropertyMissing: "Die erforderliche Angabe {property} fehlt",
  propertyInvalid: "Die Angabe {property} ist ungültig",
  paymentRefused: "Zahlung wurde abgelehnt",
  errorHappened:
    "<strong>Antrag auf Zahlungsmöglichkeit abgelehnt, bitte verwenden Sie eine andere Zahlungsmethode.</strong>",
  errorAmountHold:
    "Je nach Bank kann eine Belastung auf Ihrem Konto erscheinen. Diese ist vorübergehend und es wird kein Betrag von Ihnen abgebucht.",
  retry_error: "Antrag auf Zahlungsmöglichkeit abgelehnt.",
  ineligibilityPaymentMethod: "Bitte verwenden Sie eine andere Zahlungsmethode.",
  ineligibilityPaymentMethodRedirect:
    "Sie werden weitergeleitet, um eine andere Zahlungsmethode auszuwählen.",
  ineligibilityPaymentMethodRedirectWithMerchant:
    "Sie werden auf die Website von {merchantName} weitergeleitet, um eine andere Zahlungsmethode auszuwählen.",
  ineligibilityErrorContentWithMerchant:
    "Auf der Grundlage der von {merchantName} übermittelten oder direkt vom Zahlungsanbieter gesammelten Informationen und nach einem vollständig automatisierten Entscheidungsprozess erfüllt Ihr Antrag nicht die Kriterien für die Förderfähigkeit im Rahmen dieser Zahlungsmöglichkeit. Diese Entscheidung sagt nichts über das Ergebnis möglicher zukünftiger Finanzierungsanträge aus. Sie können uns unter {infoEmail} erreichen, um weitere Informationen zu erhalten.",
  ineligibilityErrorContent:
    "Auf der Grundlage der direkt vom Zahlungsanbieter gesammelten Informationen und als Ergebnis eines vollständig automatisierten Entscheidungsprozesses erfüllt Ihr Antrag nicht die Kriterien für die Förderfähigkeit im Rahmen dieser Zahlungsfazilität. Diese Entscheidung sagt nichts über das Ergebnis möglicher zukünftiger Finanzierungsanträge aus und Sie können uns unter {infoEmail} erreichen, um weitere Informationen zu erhalten.",
  provideMerchantUid: "Bitte geben Sie die Händler-UID an",
  invalidPhoneNumber: "Diese Telefonnummer ist ungültig.",

  IdentificationConfirmationTimeoutError:
    "Während des Verifizierungsprozesses ist ein Fehler aufgetreten. Wenn dieser erneut auftritt, kontaktieren Sie uns bitte unter {contact_email}.",
  splitIncorrectAmounts: "Die eingegebenen Beträge scheinen falsch zu sein",
  fipenDownloadError:
    "Beim Abrufen Ihrer vorvertraglichen Informationen ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
  contractDownloadError:
    "Beim Abrufen Ihrer vertraglichen Informationen ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
  contractSignError:
    "Beim Unterzeichnen Ihres Vertrags ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
  /** ----------------------------------------------------
   *                    STEPS TITLE
   *  ---------------------------------------------------- */
  title_payment_missing_info:
    "Ich vervollständige meine <span>Zahlungsinformationen</span>",
  title_payment_solutions: "Ich wähle meinen <span>Finanzierungsplan</span>",
  title_missing_info_installment:
    "Ich bezahle meinen Kauf <span>in {installmentsNb} Raten</span>",
  title_missing_info_deferred: "Ich bezahle meinen Kauf <span>später</span>",
  title_missing_info_down_payment: "Ich bezahle meinen Kauf <span>mit Anzahlung</span>",
  title_missing_info_split: "Ich bezahle meinen Kauf <span>aufgeteilt</span>",
  title_otp: "Ich bestätige mein <span>Telefon</span>",
  title_revenue_collection: "Ich gebe meine Einnahmen und <span>Ausgaben</span> an",
  title_confirm_info: "Ich bestätige meine <span>Informationen</span>",
  title_payment: "Ich gebe mein <span>Zahlungsmittel</span> ein",
  title_processing:
    "Ich lasse {operatorTeamName} meine <span>Bonität</span> überprüfen",
  /** ----------------------------------------------------
   *                        IDENTIFICATION
   *  ---------------------------------------------------- */
  identity_check: "Identitätsprüfung",
  identification_success: "Die Überprüfung war erfolgreich",
  identification_failure: "Die Überprüfung ist fehlgeschlagen",
  identification_processing: "Überprüfung läuft",
  identity_check_warning_message:
    "Sie werden gleich weitergeleitet, um Ihre Identität zu bestätigen.",
  /** ----------------------------------------------------
   *                    INTRODUCTION
   *  ---------------------------------------------------- */
  intro_request_financing: "Ich beantrage eine <span>Finanzierung</span>",
  intro_title: "Vorstellung der Zahlung in {installments_nb} Raten",
  intro_subtitle_1: "Ein Kredit verpflichtet Sie und muss zurückgezahlt werden",
  intro_subtitle_2:
    "Überprüfen Sie Ihre Rückzahlungsfähigkeit, bevor Sie eine Verpflichtung eingehen",
  intro_deposit_amount: "Anzahlung (ohne Finanzierung)",
  intro_credit_amount: "Höhe des Kredits",
  intro_financing_period: "Dauer der Finanzierung",
  intro_financing_period_value: "{installments_nb} Monate",
  intro_taeg: '<span class="text-2xl">Fester effektiver Jahreszins</span>',
  intro_credit_cost: "Kosten des Kredits",
  intro_total_amount_due:
    'Entweder ein <span class="text-2xl">geschuldeter Gesamtbetrag von</span>',
  intro_total_payment_cost:
    "Zu zahlender Gesamtbetrag (Anzahlung + Kreditfinanzierung)",
  intro_financing_modal_text:
    "<p class='text-xl font-bold mb-4'>Ein Kredit verpflichtet Sie und muss zurückgezahlt werden. Prüfen Sie Ihre Rückzahlungsfähigkeit, bevor Sie eine Verpflichtung eingehen.</p>" +
    "<p class='text-base mb-4'>Für einen Nebenkredit für einen Verkauf in Höhe von {financed_amount} zahlen Sie nach einer Anzahlung von {deposit_amount} {financing_duration_in_months} <span class='text-xl font-bold'>monatliche Raten von {derived_share_amount} zurück. Insgesamt geschuldeter Betrag von {financed_total_outstanding_amount}</span>. Die Gesamtkosten des Kaufs betragen {total_amount}. <span class='text-xl font-bold'>Fester effektiver Jahreszins von {taeg}</span>. Fester Sollzinssatz von {buyer_fees_percentage}.</p>" +
    "<p class='text-base'>Angebot eines Nebenkredits zu einem Verkauf, der Privatpersonen vorbehalten ist, vorbehaltlich der endgültigen Annahme durch CA Consumer Finance als Kreditgeber, von der Sofinco eine Marke ist. SA mit einem Kapital von 596 485 149 € - 1 rue Victor Basch - CS 70001, 91068 Massy cedex - RCS Evry 542 097 5222. Versicherungsvermittler, eingetragen bei ORIAS Nr. 07 008 079 (www.orias.fr). Für dieses Angebot wird keine Versicherung angeboten. Sie verfügen über ein gesetzliches Widerrufsrecht. Die Kosten für den Kredit werden von Ihrem Verkäufer übernommen.</p>",
  /** ----------------------------------------------------
   *                    INFORMATION MISSING
   *  ---------------------------------------------------- */
  address_street: "Adresse (Straße, Nummer etc ...)",
  address_zipcode: "Postleitzahl (z.B. 10115)",
  birth_address_zipcode: "Postleitzahl der Geburt (z.B. 10115)",
  address_city: "Stadt (z.B. Berlin)",
  civility: "Anrede (wie auf Ihrem Ausweisdokument angegeben)",
  birth_last_name: "Geburtsname (z.B. Müller)",
  madam: "Frau",
  mister: "Herr",
  address_province: "Bundesland",
  address_country: "Land (z.B. Deutschland)",
  birth_date_placeholder: "Geburtsdatum (tt.mm.jjjj)",
  birth_city: "Geburtsort",
  birth_country: "Geburtsland",
  search_birthplace: "Geburtsort suchen (z.B. 10115 Berlin, Deutschland)",
  birth_place_autocomplete_error: "Bitte wählen Sie einen Ort aus der Liste aus",
  nationality: "Staatsangehörigkeit",
  phone_number: "Telefonnummer (z.B. +491234567890)",
  missing_information_title: "Wir benötigen einige zusätzliche Informationen",
  missing_information_subtitle:
    "Um Ihren Finanzierungsantrag bearbeiten zu können, benötigen wir weitere Informationen. ",
  missing_information_subtitle_payer: "Geben Sie Ihre Rechnungsinformationen ein",
  email: "E-Mail",
  day: "Tag",
  month: "Monat",
  year: "Jahr",
  fipen_text: "Sie können jetzt ",
  fipen_link: "Ihre vorvertraglichen Informationen herunterladen.",
  /** ----------------------------------------------------
   *                    REVENUE
   *  ---------------------------------------------------- */
  title_revenue: "Wir benötigen einige zusätzliche Informationen",
  revenue_explanation:
    "Um uns von Ihrer Kreditfähigkeit zu überzeugen, benötigen wir Informationen über Ihr Einkommen sowie Ihre monatlichen Belastungen.",
  declared_revenue: "Einkommen nach Steuern",
  declared_expenditure: "Gesamtbelastung",
  declared_csp: "Berufskategorie",
  tooltip_revenue: "Nettogehalt, finanzielle Unterstützung, Immobilieneinkünfte...",
  tooltip_expenditure: "Laufende Kredite, Miete, Unterhalt",
  back_to_merchant: "Ich verstehe, bringen Sie mich zurück zum Händler",
  professional_categories: [
    "leitende Angestellte",
    "Intellektuelle und wissenschaftliche Berufe",
    "Mittlere angestellte Berufe",
    "Kleinunternehmer (Nicht-Angestellte)",
    "Fachkräfte",
    "Angestellte Facharbeiter",
    "Gering qualifizierte angestellte Berufe",
    "Rentner",
    "Sonstige Arbeitslose",
  ],
  /** ----------------------------------------------------
   *                    SUMMARY
   *  ---------------------------------------------------- */
  title_summary: "Zusammenfassung Ihres Dossiers",
  summary_explanation:
    "Bevor Sie zur Vertragsunterzeichnung übergehen, bestätigen Sie bitte Ihre Angaben.",
  summary_unchangeable: "Nicht veränderbar",
  summary_your_identity: "Ihre Identität",
  summary_your_revenue: "Ihre Finanzen",
  summary_your_contact_details: "Ihre Kontaktdaten",
  summary_deposit_amount: "Betrag der Anzahlung",
  summary_financed_amount: "Finanzierter Betrag",
  summary_total_amount_due: "Geschuldeter Gesamtbetrag",
  summary_net_monthly_income: "Monatliches Nettoeinkommen",
  summary_recurring_expenses: "Wiederkehrende Belastungen",
  summary_csp: "Berufskategorie",
  summary_acknowledgement_statement:
    "Ich bestätige, dass ich das Europäische Standardisierte Vorvertragliche Informationsblatt zur Kenntnis genommen habe. Ich bestätige ehrenwörtlich die Richtigkeit der obigen Angaben, erkläre, dass ich keinen laufenden Überschuldungsplan habe und dass das beantragte Darlehen keine Kreditzusammenlegung bezweckt.",
  summary_terms_and_conditions:
    "Ich habe die Allgemeinen Geschäftsbedingungen für digitale Dienstleistungen zur Kenntnis genommen und akzeptiere sie",
  /** ----------------------------------------------------
   *                    INFORMATION SPLIT
   *  ---------------------------------------------------- */
  title_whodoisharewith: "Mit wem teile ich meine Bestellung?",
  subtitle_addfriends: "Ich füge die E-Mail- oder Handynummern meiner Freunde hinzu",
  placeholder_friend_email_phone_number: "E-Mail oder Handynummer meines Freundes",
  attendees: "Keine Teilnehmer | 1 Teilnehmer | {count} Teilnehmer",
  pay_alone: "Ich möchte alleine bezahlen",
  /** ----------------------------------------------------
   *                        PAYMENT SOLUTIONS
   *  ---------------------------------------------------- */
  payment_solutions_title: "Meine Zahlungsmöglichkeiten",
  payment_solutions_subtitle: "bei {company}",
  payment_solutions_choice_infos:
    "Wählen Sie die Zahlungsmöglichkeit, die zu Ihnen passt",
  payment_schedule_installment_homogeneous: "{installments_nb}x {amount}",
  payment_schedule_installment_disparate:
    "{amount} dann {installments_nb}x {second_amount}",
  payment_schedule_deferred_delay_in_days: "{amount} in {delay_in_days} tagen",
  payment_schedule_deferred_day_of_next_month: "{amount} der {payment_date}",
  payment_solutions_no_merchants_found:
    "Keine Zahlungslösung erfüllt die Förderkriterien auf Grundlage der bereitgestellten Informationen.",
  /** ----------------------------------------------------
   *                        PROCESSING
   *  ---------------------------------------------------- */
  payment_pending: "Zahlung ausstehend",
  payment_pending_information_1:
    "Bitte aktualisieren Sie die Seite nicht, während die Informationen verarbeitet werden.",
  payment_pending_information_2: "Dieser Schritt kann ein paar Minuten dauern.",
  payment_cancelled: "Zahlung storniert",
  payment_analysis_needed: "Zusätzliche Informationen erforderlich",
  payment_analysis_information_1:
    "Im Rahmen Ihres Finanzierungsantrags möchten wir bei Ihrer Bank eine 90-tägige Transaktionshistorie einholen",
  payment_analysis_information_2:
    "Dieser Service wird von unserem Partner {providerLabel} betrieben, einem von der ACPR zugelassenen Marktführer für die sichere Weitergabe von Bankdaten.",
  payment_analysis_information_3:
    "Indem ich auf bestätigen klicke, stimme ich zu, die Informationen aus meinen Kontoauszügen an {operatorTeamName} zu übermitteln.",
  payment_analysis_information_4:
    "Wenn Sie dies nicht wünschen, können Sie durch Klicken auf das Kreuz zur Auswahl der Zahlungsmittel von {merchantName} zurückkehren.",
  payment_analysis_in_progress: "Analyse läuft",
  payment_analysis_progress_information_1:
    "Ihre Anfrage für eine Zahlungsmöglichkeit wird analysiert.",
  payment_analysis_progress_information_2:
    "Bitte aktualisieren Sie die Seite nicht, während Informationen verarbeitet werden. Dieser Schritt kann einige Minuten dauern.",
  /** ----------------------------------------------------
   *                        SUCCESS
   *  ---------------------------------------------------- */
  congratulations: "Herzlichen Glückwunsch!",
  payment_registered: "Ihre Zahlung wurde gespeichert.",
  payment_method_registered: "Ihre neue Zahlungsmethode wurde berücksichtigt.",
  /** ----------------------------------------------------
   *                        TWO-FA
   *  ---------------------------------------------------- */
  phone_number_check: "Überprüfung Ihrer Telefonnummer",
  phone_number_tooltip: "Dies ist die Telefonnummer, die Sie dem Händler gegeben haben",
  wrong_code: "Falscher Code, bitte versuchen Sie es erneut",
  unknown_error: "Ein unbekannter Fehler ist aufgetreten",
  code_valid: "Code ist gültig",
  please_type_code: "Geben Sie diesen Code in dem Feld unten ein",
  you_ll_receive_text: "Wir haben Ihnen gerade einen Code auf Ihr Telefon geschickt ",
  did_not_receive: "Ich habe keine Code erhalten",
  send_again: "Erneut senden",
  sending_sms: "Code Versand läuft",
  verify: "Überprüfen",
  code_transmission_error:
    "Beim Übertragen des Codes ist ein Fehler aufgetreten. Bitte versuche es erneut",

  /** ----------------------------------------------------
   *                        VALIDATIONS
   *  ---------------------------------------------------- */
  validations: {
    required: "Dieses Feld ist erforderlich",
    phone_number: "Die Telefonnummer ist ungültig",
    date_format: "Das Datumsformat ist nicht gültig ({_value_})",
    invalid_birthdate: "Bitte geben Sie ein gültiges Geburtsdatum an",
    date_between: "Das Datum ist ungültig",
    min: "Das Feld muss mindestens {length} Zeichen enthalten.",
    positive: "Der Wert muss eine positive Zahl sein.",
    email: "Das Format der E-Mail ist ungültig ({_value_})",
  },
  /** ----------------------------------------------------
   *                        VALIDATIONS
   *  ---------------------------------------------------- */
  custom_success_screen: {
    title: 'Ihre Buchung ist erst abgeschlossen, wenn Sie auf "Jetzt kaufen" klicken',
    subtitle:
      "Sobald Ihre Buchung abgeschlossen ist, wird Ihre Reise vollständig bezahlt und Sie können abreisen.",
    text: "Auf Ihrem Bankkonto kann eine Vorautorisierung erscheinen. Wir werden Sie benachrichtigen, bevor eine Zahlung fällig wird. Versäumte Zahlungen können zur Stornierung der Reise führen.",
  },
  /** ----------------------------------------------------
   *                        ERROR SCREEN
   *  ---------------------------------------------------- */
  error_screen: {
    error_410: "Die angeforderte Ressource ist nicht mehr verfügbar",
    error_404: "Fehler 404",
    expired: "Die angeforderte URL ist abgelaufen",
    not_found: "Die gesuchte Seite wurde nicht gefunden",
  },
};
