<script>
import { useFunnelStep } from "@/composables/funnelStep";
import { useError } from "@/composables/error";
import { usePlugin } from "@/composables/plugin";
import { useOperator } from "@/composables/operator";
import funnelProcessingMixin from "@/mixins/funnelProcessingMixin";

export default {
  mixins: [funnelProcessingMixin],
  setup(_, ctx) {
    const { handleError, handleInegibilityError } = useError();
    const { funnelStepDone } = useFunnelStep(ctx);
    const { postMessageParent } = usePlugin();
    const { operatorTeamName } = useOperator();
    return {
      handleError,
      handleInegibilityError,
      funnelStepDone,
      postMessageParent,
      operatorTeamName,
    };
  },

  mounted() {
    if (!this.share) throw new Error("Share is needed to call status api");
    this.checkStatus();
  },

  unmounted() {
    if (this.statusTimeoutID) {
      clearTimeout(this.statusTimeoutID);
    }
  },

  methods: {
    async checkStatus() {
      try {
        const isSeminal = this.rawIsSeminal ? 1 : 0;
        const { data, statusCode } = await this.service.getStatus(this.uid, isSeminal);
        if (statusCode !== 200) {
          /* Wait for another /status http call to decide. */
          this.retryCheckStatus();
          return;
        }
        this.endFunnel(data);
      } catch (apiResponseError) {
        this.handleStatusFailure(apiResponseError);
      }
    },
  },
};
</script>

<template lang="pug">
ScreenTitle(:title="$t('title_processing', { operatorTeamName })")

.screen.confirmation-screen.flex.flex-col.justify-around.items-center
  .screen-section.flex(style='padding-top: 30px')
    h1(v-if='errorHuman' v-t="'payment_cancelled'")
    h1(v-else v-t="'payment_pending'")

  .screen-section.section-error.text-center(v-if='errorHuman')
    p(v-html='errorHuman')

  .screen-section.loader-section.flex(v-if='isLoading')
    Loader

  .screen-section.flex.flex-col(v-if='!errorHuman')
    p.text-center.p-4.text-sm.leading-4.text-dark(v-t='informationMessage' v-for='informationMessage in informationMessages' :key='informationMessage')

  SofincoLogo
</template>
