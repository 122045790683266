<template lang="pug">
ScreenTitle(:title="$t(`title_missing_info_${paymentTypeLower}`, { installmentsNb })")

.screen.create-purchase-screen
  VVForm(ref='observer' v-slot='{ meta }' @submit.prevent='handleSubmitClick')
    .screen-section
      h1 {{ $t("missing_information_title") }}
      h3 {{ $t("missing_information_subtitle") }}
        template(v-if='isBuyerContractEligible && !isPledgLongCb && !fipenLoading') {{ $t("fipen_text") }}
          a(@click="download_fipen" data-cy="fipen_link") {{ $t("fipen_link") }}
        Loader.m-auto.mt-5(v-else-if="fipenLoading" :size="LoaderSizes.SMALL")
      .vv-error(v-if='fipenError') {{ $t("fipenDownloadError") }}

    .screen-section(v-if='submitting')
      Loader.mx-auto.my-2
    .screen-section(v-else)
      .vv-error-section(v-if='errorHumanMessage')
        span {{ errorHumanMessage }}

      template(v-if='!initial.addressValid')
        .mb-4
          VVField(
            rules='required'
            name='address_street'
            v-slot='{ field, value, errors }'
            v-model="form.address.street"
          )
            FloatingLabelField(
              field-component="el-input"
              v-bind="field"
              :model-value="value"
              size="large"
              :disabled="!!initial.address.street"
              :placeholder="$t('address_street')"
              :class='{ error: errors.length }'
              data-cy="address_street"
            )
          VVErrorMessage.vv-error(name="address_street" as="div")

        .form-column
          .flex.flex-1.flex-col
            VVField(
              rules='required'
              name='address_zipcode'
              v-slot='{ field, value, errors }'
              v-model="form.address.zipcode"
            )
              FloatingLabelField(
                field-component="el-input"
                v-bind="field"
                :model-value="value"
                size="large"
                :disabled="!!initial.address.zipcode"
                :placeholder="$t('address_zipcode')"
                :class='{ error: errors.length }'
                data-cy="address_zipcode"
              )
            VVErrorMessage.vv-error(name="address_zipcode" as="div")

          .flex.flex-1.flex-col
            VVField(
              rules='required'
              name='address_city'
              v-slot='{ field, value, errors }'
              v-model="form.address.city"
            )
              FloatingLabelField(
                field-component="el-input"
                v-bind="field"
                :model-value="value"
                size="large"
                :disabled="!!initial.address.city"
                :placeholder="$t('address_city')"
                :class='{ error: errors.length }'
                data-cy="address_city"
              )
            VVErrorMessage.vv-error(name="address_city" as="div")

        .mb-4
          VVField(
            rules='required'
            name='country'
            v-slot='{ errors }'
            v-model="form.address.country"
          )
            //- keep v-model on VVField AND el-select otherwise not working
            FloatingLabelField(
              field-component="el-select"
              v-model="form.address.country"
              size="large"
              :disabled="!!initial.address.country"
              :placeholder="$t('address_country')"
              :class='{ error: errors.length }'
              :unfocus-on-change="true"
              filterable
              :teleported="false"
              data-cy="address_country"
            )
              el-option-group(v-for="(group, key) in countries" :key="key" label="")
                el-option(v-for="item in group" :value="item.code" :key="item.code" :label="item.label" :disabled="!item.code")
          VVErrorMessage.vv-error(name="country" as="div")

      template(v-if='!initial.phoneNumberValid')
        .mb-4
          VVField(
            :rules='`required|phone_number:${rawAddress?.country}`'
            name='phone_number'
            v-slot='{ field, value, errors }'
            v-model="form.phoneNumber"
          )
            FloatingLabelField(
              field-component="el-input"
              v-bind="field"
              :model-value="value"
              size="large"
              :placeholder="$t('phone_number')"
              :class='{ error: errors.length }'
              data-cy="phone_number"
            )
          VVErrorMessage.vv-error(name="phone_number" as="div")

      template(v-if='!initial.birthDateValid')
        .mb-4
          VVField(
            :rules="!isFormBirthDateValid ? 'required|invalid_birthdate' : ''"
            name='birth_date'
            v-slot='{ field, value, errors }'
            v-model="birthDateStr"
          )
            FloatingLabelField(
              field-component="el-input"
              v-bind="field"
              :model-value="value"
              size="large"
              :placeholder="$t('birth_date_placeholder')"
              :class='{ error: errors.length }'
              type="text"
              @input="handleBirthDateInput"
              @blur="handleBirthDateBlur"
              data-cy="birth_date"
            )
          VVErrorMessage.vv-error(name="birth_date" as="div")

      template(v-if='!initial.civilityValid')
        .mb-4
          VVField(
            :rules="!isFormCivilityValid ? 'required' : ''"
            name='civility'
            v-slot='{ errors }'
            v-model="form.civility"
          )
            FloatingLabelField(
              field-component="el-select"
              v-model="form.civility"
              size="large"
              :disabled='!!initial.civility'
              :placeholder="$t('civility')"
              :class='{ error: errors.length }'
              :unfocus-on-change="true"
              :teleported="false"
              data-cy="civility"
            )
              el-option(
                v-for="civility in civilities"
                :key="civility.code"
                :label="civility.label"
                :value="civility.code"
              )
          VVErrorMessage.vv-error(name="civility" as="div")

      template(v-if='!initial.birthLastNameValid')
        .mb-4
          VVField(
            :rules="!isFormBirthLastNameValid ? 'required' : ''"
            name='birth_last_name'
            v-slot='{ field, value, errors }'
            v-model="form.birthLastName"
          )
            FloatingLabelField(
              field-component="el-input"
              v-bind="field"
              :model-value="value"
              size="large"
              :disabled='!!initial.birthLastName'
              :placeholder="$t('birth_last_name')"
              :class='{ error: errors.length }'
              data-cy="birth_last_name"
            )
          VVErrorMessage.vv-error(name="birth_last_name" as="div")


      template(v-if='!initial.birthplaceValid')
        .mb-4
          VVField(
            :rules='validateBirthPlace'
            name='birth_place_autocomplete'
            v-slot='{ field, value, errors }'
            v-model="form.birthPlaceAutocomplete"
          )
            FloatingLabelField(
              field-component="el-autocomplete"
              v-bind="field"
              :model-value="value"
              size="large"
              :placeholder="$t('search_birthplace')"
              :class='{ error: errors.length }'
              data-cy="birth_place_autocomplete"
              :fetch-suggestions="searchBirthPlaceAsync"
              :trigger-on-focus="false"
              :teleported="false"
              value-key="description"
              @select="handleSelectBirthPlace"
            )

            VVErrorMessage.vv-error(name="birth_place_autocomplete" as="div")

        .mb-4(v-if='displayBirthPostalCodeField')
          VVField(
            :rules="!isFormBirthZipcodeValid ? 'required' : ''"
            name='birth_zipcode'
            v-slot='{ field, value, errors }'
            v-model="form.birthZipcode"
          )
            FloatingLabelField(
              field-component="el-input"
              v-bind="field"
              :model-value="value"
              size="large"
              :placeholder="$t('birth_address_zipcode')"
              :class='{ error: errors.length }'
              data-cy="birth_zipcode"
            )
          VVErrorMessage.vv-error(name="birth_zipcode" as="div")

      template(v-if='!initial.nationalityValid')
        .mb-4
          VVField(
            :rules="!isNationalityValid ? 'required' : ''"
            name='nationality'
            v-slot='{ errors }'
            v-model="form.nationality"
          )
            FloatingLabelField(
              field-component="el-select"
              v-model="form.nationality"
              size="large"
              :disabled='!!initial.nationality'
              :placeholder="$t('nationality')"
              :class='{ error: errors.length }'
              :unfocus-on-change="true"
              filterable
              :teleported="false"
              data-cy="nationality"
            )
              el-option-group(v-for="(group, key) in countries" :key="key" label="")
                el-option(v-for="item in group" :value="item.code" :key="item.code" :label="item.label" :disabled="!item.code")
          VVErrorMessage.vv-error(name="nationality" as="div")

      .line_disclaimer.mt-6.px-6(v-if='!submitting')
        span(v-html="$t('data_policy', { dataPolicyUrl })")

    .screen-section.submit-section
      ElButton(
        v-if='!submitting'
        class="w-1/2"
        type="primary"
        :disabled='!meta.valid'
        @click='handleSubmitClick'
        data-cy="submit"
      )
        .grow {{ $t("submit") }}

    SofincoLogo
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { Civilities, PopularCountries } from "@/constants";
import { FRENCH_COUNTRY_CODES } from "@/constants/countries";
import COUNTRIES from "@/helpers/countryCodes";
import { Logger } from "@/utils/logger";
import { downloadPDF, getFilenameFromResponse } from "@/helpers/downloadFile";
import { PURCHASE, MERCHANT, ACCOUNT } from "@/store/namespaces";
import Loader from "@/components/BaseLoader.vue";
import { parseDate, isBirthdayAgeValid, applyDatePattern } from "@/helpers/date";
import MerchantService from "@/api/merchantService";
import GeocodingService from "@/api/geocodingService.js";
import { useFunnelStep } from "@/composables/funnelStep";
import { useOperator } from "@/composables/operator";
import SofincoLogo from "@/components/SofincoLogo.vue";
import ScreenTitle from "@/components/ScreenTitle.vue";
import {
  DATE_PICKER_FORMAT,
  DATE_PICKER_DATE_FNS_FORMAT,
} from "@/translations/locales";
import FloatingLabelField from "@/components/form/FloatingLabelField.vue";

export default {
  name: "FunnelInformationMissing",
  components: {
    Loader,
    FloatingLabelField,
    SofincoLogo,
    ScreenTitle,
  },

  props: {
    submitting: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    stopByCondition: {
      type: Boolean,
      default: false,
    },
    errorHumanMessage: {
      type: String,
      default: undefined,
    },
  },
  setup(_, ctx) {
    const { isCACFOperator } = useOperator();
    return { ...useFunnelStep(ctx), isCACFOperator };
  },

  data() {
    return {
      initial: {},
      form: {
        phoneNumber: undefined,
        address: {
          street: undefined,
          city: undefined,
          zipcode: undefined,
          country: undefined,
        },
        birthCity: undefined,
        birthZipcode: undefined,
        birthCountry: undefined,
        birthDate: undefined,
        birthDateStr: undefined,
        birthLastName: undefined,
        birthPlaceAutocomplete: undefined,
        civility: undefined,
        nationality: undefined,
      },
      fipenError: false,
      fipenLoading: false,
      // Token used to identify the session for the Places API
      searchBirthPlaceToken: undefined,
      displayBirthPostalCodeField: false,
      selectedBirthPlace: undefined,
    };
  },

  computed: {
    ...mapGetters([
      "rawAddress",
      "rawAmountCents",
      "rawLanguage",
      "rawSignature",
      "rawPhoneNumber",
      "rawBirthDate",
      "rawBirthCity",
      "rawBirthCountry",
      "rawBirthZipcode",
      "rawBirthLastName",
      "rawCivility",
      "rawNationality",
      "isAddressValid",
      "isPhoneNumberValid",
      "isBirthZipcodeValid",
      "isBirthCityValid",
      "isBirthCountryValid",
      "isBirthDateValid",
      "isBirthplaceValid",
      "isBirthLastNameValid",
      "isCivilityValid",
      "isNationalityValid",
      "validateFunctionalFields",
    ]),
    ...mapGetters(PURCHASE, ["purchase"]),
    ...mapGetters(MERCHANT, [
      "merchant",
      "merchantLanguage",
      "dataPolicyUrl",
      "isBuyerContractEligible",
      "paymentTypeLower",
      "installmentsNb",
      "isPledgLongCb",
    ]),
    ...mapGetters(ACCOUNT, {
      accountBirthDate: "birthDate",
      accountBirthCountry: "birthCountry",
      accountBirthCity: "birthCity",
      accountBirthZipcode: "birthZipcode",
      accountBirthLastName: "birthLastName",
      accountCivility: "civility",
      accountNationality: "nationality",
      isAccountBirthDateValid: "isBirthDateValid",
      isAccountBirthplaceValid: "isBirthplaceValid",
      isAccountBirthZipcodeValid: "isBirthZipcodeValid",
      isAccountBirthCityValid: "isBirthCityValid",
      isAccountBirthCountryValid: "isBirthCountryValid",
      isAccountBirthLastNameValid: "isBirthLastNameValid",
      isAccountCivilityValid: "isCivilityValid",
      isAccountNationalityValid: "isNationalityValid",
    }),
    isFormBirthZipcodeValid() {
      return this.purchase ? this.isAccountBirthZipcodeValid : this.isBirthZipcodeValid;
    },
    isFormBirthCityValid() {
      return this.purchase ? this.isAccountBirthCityValid : this.isBirthCityValid;
    },
    isFormBirthCountryValid() {
      return this.purchase ? this.isAccountBirthCountryValid : this.isBirthCountryValid;
    },
    isFormBirthDateValid() {
      return this.purchase ? this.isAccountBirthDateValid : this.isBirthDateValid;
    },
    isFormBirthLastNameValid() {
      return this.purchase
        ? this.isAccountBirthLastNameValid
        : this.isBirthLastNameValid;
    },
    isFormCivilityValid() {
      return this.purchase ? this.isAccountCivilityValid : this.isCivilityValid;
    },
    isFormNationalityValid() {
      return this.purchase ? this.isAccountNationalityValid : this.isNationalityValid;
    },
    countries() {
      const allCountries = COUNTRIES[this.$i18n.locale];
      const popularSet = new Set([...PopularCountries, this.merchant.country_code]);

      const popularCountries = [];
      const otherCountries = [];

      allCountries.forEach((country) => {
        (popularSet.has(country.code) ? popularCountries : otherCountries).push(
          country
        );
      });
      return { popular: popularCountries, other: otherCountries };
    },
    civilities() {
      return [
        { code: Civilities.MR, label: this.$t("mister") },
        { code: Civilities.MRS, label: this.$t("madam") },
      ];
    },
    // Synchronizing with form.birthDateStr to avoid conflicting events
    birthDateStr: {
      get() {
        return this.form.birthDateStr;
      },
      set(value) {
        this.form.birthDateStr = applyDatePattern(value, this.getDateFormat());
      },
    },
  },

  watch: {
    "form.birthCountry": function (newValue) {
      // Important to update in real time to determine whether birthZipcode is mandatory
      if (this.purchase) {
        this.setAccountBirthCountry({ birthCountry: newValue });
      } else {
        this.setBirthCountry({ birthCountry: newValue });
      }
    },
  },

  beforeMount() {
    this.saveInitialData();
    this.setDataFromRaw();
  },

  async mounted() {
    if (!this.stopByCondition) {
      // Go to next step if stopByCondition is false
      this.funnelStepDone();
    }
  },

  methods: {
    ...mapActions(["setBirthCountry"]),
    ...mapActions(ACCOUNT, { setAccountBirthCountry: "setBirthCountry" }),
    saveInitialData() {
      this.initial.phoneNumber = this.rawPhoneNumber;
      this.initial.address = this.rawAddress ? { ...this.rawAddress } : {};
      this.initial.phoneNumberValid = this.purchase ? true : this.isPhoneNumberValid;
      this.initial.addressValid = this.purchase ? true : this.isAddressValid;
      if (this.purchase) {
        this.initial.birthCity = this.accountBirthCity;
        this.initial.birthZipcode = this.accountBirthZipcode;
        this.initial.birthCountry = this.accountBirthCountry;
        this.initial.birthDate = this.accountBirthDate;
        this.initial.birthDateValid = this.isAccountBirthDateValid;
        this.initial.birthplaceValid = this.isAccountBirthplaceValid;
        this.initial.birthLastName = this.accountBirthLastName;
        this.initial.civility = this.accountCivility;
        this.initial.nationality = this.accountNationality;
        this.initial.birthLastNameValid = this.isFormBirthLastNameValid;
        this.initial.civilityValid = this.isFormCivilityValid;
        this.initial.nationalityValid = this.isFormNationalityValid;
      } else {
        this.initial.birthCity = this.rawBirthCity;
        this.initial.birthZipcode = this.rawBirthZipcode;
        this.initial.birthCountry = this.rawBirthCountry;
        this.initial.birthDate = this.rawBirthDate;
        this.initial.birthDateValid = this.isBirthDateValid;
        this.initial.birthplaceValid = this.isBirthplaceValid;
        this.initial.birthLastName = this.rawBirthLastName;
        this.initial.civility = this.rawCivility;
        this.initial.nationality = this.rawNationality;
        this.initial.birthLastNameValid = this.isBirthLastNameValid;
        this.initial.civilityValid = this.isCivilityValid;
        this.initial.nationalityValid = this.isNationalityValid;
      }
    },
    setDataFromRaw() {
      this.form.phoneNumber = this.rawPhoneNumber;
      this.form.address = this.rawAddress ? { ...this.rawAddress } : this.form.address;
      if (this.purchase) {
        this.form.birthCity = this.accountBirthCity;
        this.form.birthZipcode = this.accountBirthZipcode;
        this.form.birthCountry = this.accountBirthCountry;
        if (this.initial.birthDateValid) {
          this.form.birthDate = parseDate(this.accountBirthDate);
        }
        this.form.birthLastName = this.accountBirthLastName;
        this.form.civility = this.accountCivility;
        this.form.nationality = this.accountNationality;
      } else {
        this.form.birthCity = this.rawBirthCity;
        this.form.birthZipcode = this.rawBirthZipcode;
        this.form.birthCountry = this.rawBirthCountry;
        if (this.initial.birthDateValid) {
          this.form.birthDate = parseDate(this.rawBirthDate);
        }
        this.form.birthLastName = this.rawBirthLastName;
        this.form.civility = this.rawCivility;
        this.form.nationality = this.rawNationality;
      }
    },
    async handleSubmitClick() {
      this.onSubmit(this.form);
    },
    async download_fipen() {
      this.fipenError = false;
      this.fipenLoading = true;
      const params = this.purchase?.is_purchase_link
        ? { purchase_uid: this.purchase.uid }
        : { signature: this.rawSignature };
      try {
        const res = await MerchantService.fetchFipen(this.merchant.uid, params, {
          responseType: "blob",
        });
        const filename = getFilenameFromResponse(res);
        downloadPDF(res.data, filename);
      } catch {
        this.fipenError = true;
      } finally {
        this.fipenLoading = false;
      }
    },
    async searchBirthPlaceAsync(inputValue) {
      if (!this.searchBirthPlaceToken) this.searchBirthPlaceToken = uuidv4();
      try {
        const response = await GeocodingService.getPlaces(
          this.merchant.uid,
          inputValue,
          this.purchase?.language || this.rawLanguage || this.merchantLanguage,
          this.searchBirthPlaceToken
        );
        const { success, data } = response.data;
        if (!success) {
          Logger.error({ id: "searchBirthPlaceAsync", response });
          return [];
        }
        return data;
      } catch (error) {
        Logger.error({ id: "searchBirthPlaceAsync", error });
        return [];
      }
    },
    async handleSelectBirthPlace(item) {
      try {
        this.displayBirthPostalCodeField = false;

        const response = await GeocodingService.getPlaceDetails(
          this.merchant.uid,
          item.place_id,
          this.purchase?.language || this.rawLanguage || this.merchantLanguage,
          this.searchBirthPlaceToken
        );
        const { success, data } = response.data;
        if (!success) {
          Logger.error({ id: "handleSelectBirthPlace", response });
        }
        this.selectedBirthPlace = item;
        this.form.birthCity = data.city;
        this.form.birthZipcode = data.postal_code || this.form.birthZipcode;
        this.form.birthCountry = data.country_code || this.form.birthCity;
        const isBirthPlaceValid = this.validateBirthPlace(
          this.form.birthPlaceAutocomplete
        );
        if (isBirthPlaceValid === true) {
          this.$refs.observer.setFieldError("birth_place_autocomplete", null);
        }

        // Display the birth zipcode field only if the country is France,
        // the postal code is not found or not displayed in the search field
        if (
          isBirthPlaceValid === true &&
          FRENCH_COUNTRY_CODES.includes(this.form.birthCountry) &&
          (!this.form.birthZipcode ||
            !item.description.includes(this.form.birthZipcode))
        ) {
          this.displayBirthPostalCodeField = true;
        }
        // Once a place is selected, we clear the token to allow a new search
        // https://developers.google.com/maps/documentation/places/web-service/session-tokens?hl=fr
        this.searchBirthPlaceToken = undefined;
      } catch (error) {
        Logger.error({ id: "handleSelectBirthPlace", error });
      }
    },
    validateBirthPlace(value) {
      if (
        !value ||
        !this.selectedBirthPlace ||
        !this.selectedBirthPlace?.description.includes(value)
      ) {
        // Return the error message to display it in the UI
        return this.$t("birth_place_autocomplete_error");
      }

      // birth city may be empty when a country is selected (i.e. France)
      if (!this.form.birthCity) {
        return this.$t("birth_place_autocomplete_error");
      }
      return true;
    },
    disableBirthDates(date) {
      return !isBirthdayAgeValid(date);
    },
    getDateFormat(formatterDict = DATE_PICKER_FORMAT) {
      return formatterDict[this.$i18n.locale];
    },
    handleBirthDateInput(value) {
      this.birthDateStr = value;
    },
    handleBirthDateBlur() {
      const newBirthDate = parseDate(
        this.form.birthDateStr,
        this.getDateFormat(DATE_PICKER_DATE_FNS_FORMAT)
      );
      if (newBirthDate) {
        this.form.birthDate = newBirthDate;
      }
    },
  },
};
</script>
