<script>
import { mapActions, mapGetters } from "vuex";
import _find from "lodash.find";
import config from "@/config";
import {
  ShareTypes,
  SHARE_STATES_PAID,
  SHARE_STATES_OK,
  PurchaseStates,
  ShareStates,
  RECEIVABLE_SHARE_TYPES,
} from "@/constants";
import LocalizedAmount from "@/components/LocalizedAmount.vue";
import { useI18n } from "@/composables/i18n";
import StateTag, { TAG_TYPES } from "./StateTag.vue";
import ResendInvitationForm from "./ResendInvitationForm.vue";
import { Logger } from "../../../utils/logger";
import { MERCHANT, PURCHASE, SHARE } from "../../../store/namespaces";
import TransactionTitle from "./TransactionTitle.vue";
import StatusPanel from "./StatusPanel.vue";

const FETCH_PURCHASE_INTERVAL_IN_MS = 5000;

const DASHBOARD_BUYER_PURCHASE_STATES = [
  PurchaseStates.PAYMENT_OK,
  PurchaseStates.COLLECTING,
  PurchaseStates.RELEASED,
];

export default {
  components: {
    StateTag,
    ResendInvitationForm,
    TransactionTitle,
    LocalizedAmount,
    StatusPanel,
  },
  setup(_, ctx) {
    const { changeLocale } = useI18n(ctx);
    return { changeLocale };
  },
  data() {
    return {
      ShareTypes,
      dashboardNotAvailable: false,
      loading: true,
      fetchPurchaseInterval: undefined,
    };
  },
  computed: {
    ...mapGetters([
      "rawPurchaseUid",
      "rawMerchantUid",
      "rawPledgEnv",
      "rawCountryCode",
    ]),
    ...mapGetters(MERCHANT, [
      "merchant",
      "merchantName",
      "isSplitPayment",
      "isInstallmentPayment",
      "isDeferredPayment",
      "paymentTypeLower",
      "installmentsNb",
    ]),
    ...mapGetters(PURCHASE, ["purchase"]),
    ...mapGetters(SHARE, ["leaderShare", "pledgerShares", "purchaseShares"]),
    //
    futureShare() {
      if (this.isSplitPayment) return undefined;
      return _find(Object.values(this.purchaseShares), (share) => this.isFuture(share));
    },
    hasFutureShares() {
      return !!this.futureShare;
    },
    overdueShare() {
      return _find(Object.values(this.purchaseShares), (share) =>
        this.isOverdue(share)
      );
    },
    hasOverdue() {
      return !!this.overdueShare;
    },
    lastPspSource() {
      return this.purchase.last_psp_source;
    },
    pspSourceExpired() {
      return this.lastPspSource?.expired || false;
    },
    pspSourceExpiredSoon() {
      return this.lastPspSource?.expired_soon || false;
    },
    displayPaymentMethodUpdateBox() {
      if (this.isSplitPayment || this.purchase.state !== PurchaseStates.COLLECTING) {
        return false;
      }
      return true;
    },
    displayPaymentMethodUpdateBoxButton() {
      if (config.UPDATE_PAYMENT_METHOD_DISABLED) {
        Logger.info({ id: "update_payment_method_disabled" });
        return false;
      }

      if (
        !this.hasOverdue &&
        this.hasFutureShares &&
        RECEIVABLE_SHARE_TYPES.includes(this.futureShare.share_type)
      ) {
        return !!this.futureShare.payment_method_update_url;
      }
      return false;
    },
  },
  watch: {
    purchase(newValue) {
      if (
        !DASHBOARD_BUYER_PURCHASE_STATES.includes(newValue.state) &&
        !this.dashboardNotAvailable
      ) {
        this.dashboardNotAvailable = true;
        throw new Error("purchase-overview-created-error");
      }
    },
    dashboardNotAvailable(newValue) {
      if (newValue) {
        this.clearFetchPurchaseInterval();
      }
    },
  },

  async created() {
    try {
      await this.fetchPurchase(true);
      await this.fetchMerchantAction({
        merchantUid: this.purchase?.merchant,
        pledgEnv: this.rawPledgEnv,
      });
      // When the merchant config overrides the purchase language
      this.changeLocale(this.purchase.language);
    } catch (error) {
      Logger.error({ id: "purchase-overview-created-error", error });
      this.dashboardNotAvailable = true;
      throw new Error("purchase-overview-created-error");
    } finally {
      this.loading = false;
    }
  },
  mounted() {
    this.fetchPurchaseInterval = setInterval(() => {
      this.fetchPurchase();
    }, FETCH_PURCHASE_INTERVAL_IN_MS);
  },
  destroyed() {
    this.clearFetchPurchaseInterval();
  },
  methods: {
    ...mapActions(PURCHASE, ["fetchPurchaseAction"]),
    ...mapActions(MERCHANT, ["fetchMerchantAction"]),
    shareStateLabel(share) {
      if (!share.is_paid) {
        if (share.share_type === ShareTypes.LEADER) return "state_leader_not_paid";
        if (share.share_type === ShareTypes.ANCV) return "state_ancv_processing";
        if (share.expired) return "state_expired_not_paid";
        return "state_future_not_paid";
      }
      if (SHARE_STATES_OK.includes(share.state)) {
        if (share.is_full_refund) return "state_fully_refunded";
        if (share.is_full_not_charge) return "state_fully_not_charged";
        if (
          share.state === ShareStates.PREAUTHORIZATION_OK &&
          share.share_type === ShareTypes.ANCV
        ) {
          return "state_ancv_preauthorization_ok";
        }
        return `state_${share.state.toLowerCase()}`;
      }
      return "state_unknown";
    },
    canBePaid(share) {
      return !!(!share.is_paid && share.share_type !== ShareTypes.ANCV);
    },
    isOverdue(share) {
      return !!(this.canBePaid(share) && share.expired && share.payment_url);
    },
    isFuture(share) {
      return !!(
        this.canBePaid(share) &&
        !share.expired &&
        share.payment_method_update_url
      );
    },
    hasFees(share) {
      return share?.localized_effective_fees_amount?.amount !== "0";
    },
    hasPenalties(share) {
      return share?.localized_effective_penalties_amount?.amount !== "0";
    },
    shareStateTagType(share) {
      if (this.isOverdue(share)) return TAG_TYPES.DANGER;
      if (SHARE_STATES_PAID.includes(share.state)) return TAG_TYPES.SUCCESS;
      return TAG_TYPES.INFO;
    },
    displayPartialCapturedAmount(share) {
      return (
        this.isOverdue(share) &&
        share.localized_total_effective_captured_amount.amount !== "0"
      );
    },
    async fetchPurchase(is_exception = false) {
      try {
        await this.fetchPurchaseAction({
          purchaseUid: this.rawPurchaseUid,
          isDashboardBuyer: true,
        });
      } catch (apiResponseError) {
        this.clearFetchPurchaseInterval();
        if (is_exception) throw apiResponseError;
      }
    },
    clearFetchPurchaseInterval() {
      if (this.fetchPurchaseInterval) {
        clearInterval(this.fetchPurchaseInterval);
      }
    },
  },
};
</script>

<template lang="pug">
div(v-if="purchase && merchant")
  .pb-4(v-if="purchase.order_dashboard_url")
    a.text-bold.no-underline(:href="purchase.order_dashboard_url") &lt; {{ $t("dashboard_order_link") }}

  h1.text-2xl.pt-6.pb-4(
    class='sm:text-left'
    v-html="$t(`dashboard_buyer_title_${paymentTypeLower}`, { installmentsNb, merchantName })"
  )

  TransactionTitle(:title="purchase.title" :subtitle="purchase.subtitle")

  .overview-content

    StatusPanel(:purchases="[purchase]" :localizedCreated="purchase.localized_created")

    .box-container
      //----------------------------------------------------
      //-
      //-             SUMMARY BOX
      //-
      //----------------------------------------------------
      .box-section(data-cy="purchase_infos")
        h4(v-t="'your_purchase'")

        .border-b.border-color.my-4.pb-4
          .box-item
            span {{  $t('dashboard_buyer_your_basket') }}
            LocalizedAmount(:localizedAmount='purchase.localized_amount')

          .box-item
            span {{ $t('dashboard_buyer_your_fees', { feesPercentage: purchase.buyer_fees_percentage })}}
            LocalizedAmount(:localizedAmount='purchase.localized_fees_amount')

      //----------------------------------------------------
      //-
      //-             SPLIT
      //-
      //----------------------------------------------------
      template(v-if='isSplitPayment')
        .box-section(data-cy="shared_payment_infos")

          .box-item-grid.grid.font-semibold(class='grid-cols-2 sm:grid-cols-6')
            span {{ $t("header_amount") }}
            div(class='sm:col-span-2') {{ $t("header_status") }}
            div(class='sm:col-span-3' v-if="!$isMobile()") {{ $t("header_participants") }}

          div(v-for='share in [leaderShare, ...pledgerShares]' :key='share.uid')
            .box-item-grid.bigger.grid(:key='share.uid' class='grid-cols-2 sm:grid-cols-6')
              LocalizedAmount(:localizedAmount='share.localized_complete_amount')

              StateTag.split(
                :text='$t(shareStateLabel(share))'
                :tagType='shareStateTagType(share)'
                class='sm:col-span-2'
              )

              div(class='col-span-2 sm:col-span-3 border-b pb-4 sm:border-none sm:pb-0')
                template(v-if='share.uid == leaderShare.uid')
                  span(v-if="!$isMobile()") {{ $t('split_leader_label') }}
                template(v-else)
                  template(v-if='share.can_resend_invitation')
                    ResendInvitationForm(
                      :defaultDestination='share.holder_access_details'
                      :countryCode='rawCountryCode || merchant.country_code'
                      :shareUid='share.uid'
                    )
                  span(v-else) {{ share.holder_access_details }}


        .box-section.pt-8(v-if='purchase.has_pledgers_shares_not_paid')
          strong {{ $t("split_explanation_1", { ...leaderShare.localized_expiration_date, ...leaderShare.localized_complete_amount }) }}



      //----------------------------------------------------
      //-
      //-             INSTALLMENT / DEFERRED
      //-
      //----------------------------------------------------
      template(v-else-if='isInstallmentPayment || isDeferredPayment')

        .box-section(data-cy="payment_schedule")
          h4(v-t="'dashboard_buyer_payment_schedule'")

          div(v-for='share in purchaseShares' :key='share.uid')
            .box-item.with-border-right
              .flex-1
                span {{ $t('on_date', { date: share.localized_expiration_date.date}) }}

              StateTag(:text='$t(shareStateLabel(share))' :tagType='shareStateTagType(share)' class='sm:flex-1')

              .flex.flex-col.flex-1
                .box-item-row
                  template(v-if='!$isMobile()')
                    template(v-if='hasFees(share)' data-cy="has_fees")
                      span.text-gray-400.italic.mr-2(class='text-2xs sm:text-xs') {{ $t("fees_amount", { ...share.localized_effective_fees_amount }) }}
                    template(v-if='hasPenalties(share)' data-cy="has_penalties")
                      span.text-gray-400.italic.mr-2(class='text-2xs sm:text-xs') {{ $t("penalties_amount", { ...share.localized_effective_penalties_amount }) }}

                  LocalizedAmount(:localizedAmount='share.localized_complete_amount')

                .box-item-row(v-if='$isMobile()')
                  template(v-if='hasFees(share)' data-cy="has_fees")
                    .box-item-details
                      .flex.justify-end.text-gray-400
                        span.italic {{ $t("fees_amount", { ...share.localized_effective_fees_amount }) }}

                  template(v-if='hasPenalties(share)' data-cy="has_penalties")
                    .box-item-details
                      .flex.justify-end.text-gray-400
                        span.italic {{ $t("penalties_amount", { ...share.localized_effective_penalties_amount }) }}

                // Partial capture
                .box-item-row.flex-col.align-end(v-if='displayPartialCapturedAmount(share)' data-cy="box-item-partial-captured")
                  .box-item-details
                    .flex.justify-end.text-gray-400
                      span.italic {{ $t('dashboard_buyer_share_captured_amount') }}
                      LocalizedAmount(:localizedAmount='share.localized_total_effective_captured_amount')

                  .box-item-details
                    .flex.justify-end
                      span.italic.text-gray-400 {{ $t('dashboard_buyer_share_outstanding_amount') }}
                      LocalizedAmount.text-failure(:localizedAmount='share.localized_total_outstanding_amount')

                // Not charged
                .box-item-row(v-if='share.is_partial_not_charge || share.is_full_not_charge' data-cy="box-item-not-charged")
                  .box-item-details
                    .flex.justify-end.text-gray-400
                      span.italic {{ $t('dashboard_buyer_share_not_charged_amount') }}
                      LocalizedAmount(:localizedAmount='share.localized_total_not_charged_amount')

                // Refunds
                .box-item-row(v-if='share.is_partial_refund || share.is_full_refund' data-cy="box-item-not-refunded")
                  .box-item-details
                    .flex.justify-end.text-gray-400
                      span.italic {{ $t('dashboard_buyer_share_refunded_amount') }}
                      LocalizedAmount(:localizedAmount='share.localized_total_effective_refunded_amount')



    //----------------------------------------------------
    //-
    //-             OVERDUE BOX
    //-
    //----------------------------------------------------
    .box-container.danger(v-if='hasOverdue')
      .box-section(data-cy="overdue_payment")
        h4(v-t="'dashboard_buyer_overdue_payment'")

        .box-item
          span {{ $t('dashboard_buyer_overdue_payment_text', { date: overdueShare.localized_expiration_date.date }) }}

        .box-item.center.py-4
          ElButton.sm(
            type="primary"
            data-cy="overdue_payment_button"
          )
            a(:href='overdueShare.payment_url' target='_blank') {{ $t('proceed_payment_late', { ...overdueShare.localized_total_outstanding_amount}) }}

    //----------------------------------------------------
    //-
    //-             YOUR PAYMENT METHOD
    //-
    //-    - When one share is overdue: redirect to payment page
    //-    - When everything is normal: ability to create a new payment method using preauth
    //-
    //----------------------------------------------------
    .box-container(
      v-if="displayPaymentMethodUpdateBox"
      :class="{danger: hasOverdue || pspSourceExpired, warning: pspSourceExpiredSoon}"
    )
      .box-section(data-cy="payment_method_update")
        h4(v-t="'dashboard_buyer_your_payment_method'")

        .box-item
          template(v-if='pspSourceExpiredSoon')
            span {{ $t('dashboard_buyer_your_payment_method_expired_soon') }}
          template(v-else-if='isDeferredPayment')
            span {{ $t('dashboard_buyer_your_payment_method_text_deferred') }}
          template(v-else)
            span {{ $t('dashboard_buyer_your_payment_method_text_installment') }}

        .box-item.py-4
          .flex.items-center
            template(v-if="lastPspSource")
              component.w-6(:is="`icon-source-type-${lastPspSource.source_type}`")
              template(v-if="lastPspSource.last_digits")
                span.ml-4 XXXX XXXX XXXX {{ lastPspSource.last_digits }}
              template(v-else)
                span.ml-4.italic {{ $t('no_information') }}
            template(v-else)
              span.italic {{ $t('no_psp_source') }}

        .box-item.center.pb-4(v-if="displayPaymentMethodUpdateBoxButton")
          ElButton.sm(
            type="primary"
            data-cy="payment_method_update_button"
          )
            a(
              :href='futureShare.payment_method_update_url'
              target='_blank'
            ) {{ $t('dashboard_buyer_your_payment_method_button') }}
</template>
