<script>
import { Close } from "@element-plus/icons-vue";

export default {
  name: "DialogModal",
  components: {
    Close,
  },
  props: {
    triggerLabel: {
      type: String,
      required: true,
    },
    modalTitle: {
      type: String,
      default: undefined,
    },
    modalContent: {
      type: String,
      required: true,
    },
    cancelLabel: {
      type: String,
      default: undefined,
    },
    confirmLabel: {
      type: String,
      default: undefined,
    },
  },
  emits: ["confirmModal"],
  data() {
    return {
      open: false,
    };
  },
};
</script>

<template lang="pug">
  a.trigger-label(@click="open = true") {{ triggerLabel }}
  Teleport(to="body" v-if="open")
    .relative.z-30(role="dialog" aria-modal="true")
      .modal-overlay(aria-hidden="true" @click="open = false")
      .modal-view
        .modal-container
          .modal-content
            .modal-body
              h3.modal-title(v-if="modalTitle" v-html="modalTitle" )
              .modal-close(@click="open = false")
                Close(class="h-6 w-6")
              p.text-justify.mt-4(v-html="modalContent")
            .modal-footer(v-if="confirmLabel || cancelLabel")
              button.modal-button.modal-confirm(type="button" @click="$emit('confirmModal'); open = false;") {{ confirmLabel }}
              button.modal-button.modal-cancel(type="button" @click="open = false") {{ cancelLabel }}
</template>

<style scoped lang="scss">
.trigger-label {
  @apply text-base font-normal
  text-secondary;
}
.modal-overlay {
  @apply fixed inset-0 bg-gray-500/75
  transition-opacity;
}
.modal-view {
  @apply fixed inset-0 z-10 w-screen
  overflow-y-auto pointer-events-none;
}
.modal-container {
  @apply flex min-h-full justify-center
  p-4 text-center items-center;
}
.modal-body {
  @apply bg-white p-6;
}
.modal-footer {
  @apply bg-gray-50 px-4 py-3 sm:flex
  sm:flex-row-reverse sm:px-6;
}
.modal-close {
  @apply absolute top-0 right-0 px-6 py-3
  text-secondary cursor-pointer sm:block;
}
.modal-content {
  @apply relative transform overflow-hidden rounded-lg
  bg-white text-left shadow-xl transition-all
  pointer-events-auto sm:my-8 sm:w-full sm:max-w-lg;
}
.modal-title {
  @apply text-xl font-bold text-gray-900;
}
.modal-button {
  @apply inline-flex w-full justify-center rounded-md
  px-3 py-2 text-sm font-semibold sm:w-auto;
}
.modal-confirm {
  @apply bg-primary text-white sm:ml-3;
}
.modal-cancel {
  @apply bg-white text-gray-900 ring-1
  ring-gray-300 ring-inset sm:mt-0;
}
</style>
